.ash-center-img {
	margin-top: 70px;
	margin-left: 8%;
	display: flex;
	flex-direction: column;
	row-gap: 50px;
	width: 75%;
	justify-content: center;
}

.ash-testimonial-area {
	padding: 40px 80px 80px 40px;
}

.ash-yt {
	width: 70%;
	margin: 40px auto;
}

.as-gallery {
	margin: 15px 15px;
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	margin: -1rem -1rem;
}

.gallery-item {
	flex: 1 0 24rem;
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}

@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2rem;
	}

	.gallery,
	.gallery-item {
		margin: 0;
	}
}

.mt-ash {
	margin-top: 20px;
}

.wd-ash {
	width: 90%;
	margin: auto;
	padding-top: 40px;
}


.zoomWrapper {
	max-width: 400px;
	overflow: hidden;
	margin: 0 auto 40px auto;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

.zoomWrapper img {
	max-width: 100%;
	height: auto;
	display: block;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

.zoomWrapper img:hover {
	-webkit-transform: scale(1.25);
	-moz-transform: scale(1.25);
	-ms-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}

.ash-margin-bottom {
	margin-bottom: 80px;
}