.ash-tab-div {
    width: 80%;
    margin: 40px auto;
}

.ash-tab-head {
    margin: 30px 0;
}

.galleryash {
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
	margin: -1rem -1rem;
}

.galleryash-item {
    width: 270px;
	margin: 1rem;
    padding: 10px;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.galleryash-image {
	display: block;
	width: 100%;
	height: 90%;
	object-fit: cover;
	transition: transform 600ms ease-out;
}

.galleryash-image:hover {
	transform: scale(1.15);
}

.ashText {
    text-align: center;
    color: black;
    font-size: 20px;
    font-weight: 600;
}

.margin-div {
	width: 100vw;
	height: 50px;
}